import { useState } from "react";
import { t } from "ttag";

import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type { SubscriptionDurationInMonths } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { Tabs } from "@/ui";
import { SubscriptionPlans } from "./components";
import { usePrefetchSubscriptions } from "./hooks";

export const SubscriptionContent = () => {
  const [tabValue, setTabValue] = useState<SubscriptionDurationInMonths>(
    SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY,
  );

  const setSubscriptionPlan = useOnboardingStore(
    (state) => state.setSubscriptionPlan,
  );

  const prefetchSubscriptions = usePrefetchSubscriptions();

  const handleTabChange = (value: string) => {
    setTabValue(value as SubscriptionDurationInMonths);

    setSubscriptionPlan(null);
  };

  return (
    <Tabs.Root
      value={tabValue}
      onValueChange={handleTabChange}
      className="flex h-full flex-col"
    >
      <Tabs.List>
        <Tabs.Trigger
          value={SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY}
          className="flex-col"
          onMouseEnter={() =>
            prefetchSubscriptions(SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY)
          }
        >
          <h4 className="text-sm font-medium">{t`Monthly`}</h4>
        </Tabs.Trigger>

        <Tabs.Trigger
          value={SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY}
          className="flex-col"
          onMouseEnter={() =>
            prefetchSubscriptions(SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY)
          }
        >
          <h4 className="text-sm font-medium">{t`Bundle`}</h4>
        </Tabs.Trigger>
      </Tabs.List>

      <div className="grow lg:py-4">
        <Tabs.Content
          value={SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            {t`Unlock exclusive benefits with our easy-access subscription. Join today and start enjoying the perks!`}
          </p>
          <SubscriptionPlans durationInMonths={tabValue} />
        </Tabs.Content>

        <Tabs.Content
          value={SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            <strong>{t`Cover all 3 months of doses with a single order!`}</strong>
            {t` No fuss—place your order and check in with us monthly to track your progress.`}
          </p>
          <SubscriptionPlans durationInMonths={tabValue} />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};

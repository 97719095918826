import { ScreenLayout } from "@/layouts";
import { PORTAL_USER_TYPE, SUBSCRIPTION_STATUS } from "@/shared.constants";
import { usePortalUserStore } from "@/stores";
import { tw } from "@/utils";
import {
  BillingHeader,
  CurrentSubscriptionCard,
  PaymentHistory,
  PaymentMethod,
} from "./sections";

export const Overview = () => {
  const portalUser = usePortalUserStore((state) => state.portalUser);

  const isCancelledUser =
    portalUser?.subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED;

  const isCoachingUser = portalUser?.type === PORTAL_USER_TYPE.COACHING;

  return (
    <ScreenLayout>
      <BillingHeader />

      <div className={tw("flex flex-col-reverse gap-3 xl:flex-row")}>
        {!isCoachingUser && <CurrentSubscriptionCard />}

        {!isCancelledUser && <PaymentMethod />}
      </div>

      <PaymentHistory />
    </ScreenLayout>
  );
};

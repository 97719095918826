const BASE_ROUTE = "/embeddables-onboarding";

export const EMBEDDABLES_ONBOARDING_ROUTE = {
  BASE: BASE_ROUTE,
  ACCOUNT: `${BASE_ROUTE}/account`,
  MAIN_INFO: `${BASE_ROUTE}/main-info`,
  CHOOSE_JOURNEY: `${BASE_ROUTE}/choose-journey`,
  CHOOSE_JOURNEY_SCHEDULE: `${BASE_ROUTE}/choose-journey/schedule`,
  INTAKE: `${BASE_ROUTE}/intake-form`,
  INTAKE_REFILL_REQUEST: `${BASE_ROUTE}/intake-form/refill-request`,
  INTAKE_QUESTIONS: `${BASE_ROUTE}/intake-form/questions`,
  INTAKE_ADDITIONAL: `${BASE_ROUTE}/intake-form/additional-files`,
  SUCCESS: `${BASE_ROUTE}/start-your-journey`,
} as const;

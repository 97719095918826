import { useState } from "react";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { env } from "@/env";
import { useSubscriptionPlansQuery } from "@/hooks";
import { ErrorModal } from "@/modals";
import { ROUTES } from "@/router";
import { CONSULTATION_PURPOSE } from "@/shared.constants";
import type { ModalProps, SubscriptionPlan } from "@/shared.types";
import { useConsultationStore } from "@/stores";
import {
  AltArrowRightIcon,
  Button,
  Dialog,
  PillsFixedIcon,
  RadioGroupCards,
} from "@/ui";
import { SubscriptionOptions } from "./components";

export const MedicationSelectModal = ({ show, onClose }: ModalProps) => {
  const [selectedPlan, setSelectedPlan] =
    useState<SubscriptionPlan["subscriptionPriceId"]>();
  const setPurpose = useConsultationStore((state) => state.setPurpose);

  const selectedPlanIsTirzepatide =
    selectedPlan === env.VITE_STRIPE_TIRZEPATIDE_MONTHLY_PRICE_ID;

  const { data, isSuccess, isLoading, isError } = useSubscriptionPlansQuery();

  const navigate = useNavigate();

  const handleNext = () => {
    if (selectedPlanIsTirzepatide) {
      setPurpose(CONSULTATION_PURPOSE.CONSULTATION);
    }

    navigate(ROUTES.CONSULTATIONS.MEDICATION_REFILL_REQUEST);
  };

  const handleClose = () => {
    onClose();
    setSelectedPlan(undefined);
  };

  if (isError) {
    return <ErrorModal show={show} onClose={handleClose} />;
  }

  return (
    <Dialog.Root open={show} onOpenChange={handleClose}>
      <Dialog.Content size="modal">
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <PillsFixedIcon />
            </Dialog.Icon>

            <Dialog.Title>
              {t`What medication do you want to refill?`}
            </Dialog.Title>

            <VisuallyHidden asChild>
              <Dialog.Description>
                {t`Please select the medication you'd like to refill from the list below.`}
              </Dialog.Description>
            </VisuallyHidden>
          </Dialog.Header>

          {isLoading && <RadioGroupCards.Skeleton />}

          {isSuccess && (
            <SubscriptionOptions
              value={selectedPlan}
              onValueChange={setSelectedPlan}
              subscriptionPlans={data}
            />
          )}

          <Dialog.Footer variant="sticky" className="sm:justify-end">
            {!isError ? (
              <Button
                disabled={!selectedPlan}
                onClick={handleNext}
                size="lg"
                className="justify-self-end"
              >
                {selectedPlanIsTirzepatide
                  ? t`Schedule video consultation`
                  : t`Next`}
                <AltArrowRightIcon className="hidden size-4 sm:block" />
              </Button>
            ) : (
              <Button
                onClick={() => navigate(ROUTES.MESSAGES)}
                size="lg"
                className="justify-self-end"
              >
                {t`Contact support`}
              </Button>
            )}
          </Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

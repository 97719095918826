import { z } from "zod";

import {
  getDateOfBirthSchema,
  getLocationSchema,
  journeySchema,
} from "@/shared.schemas";

export const embeddablesUserSchema = z.object({
  isValid: z.boolean(),
  email: z.string().email(),
});

export const getMainInfoFormValuesSchema = () =>
  z.object({
    dob: getDateOfBirthSchema(),
    location: getLocationSchema(),
  });

export const getChooseJourneyValuesSchema = () =>
  z.object({
    journey: journeySchema,
  });

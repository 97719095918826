import { useFormContext } from "react-hook-form";

import { SubscriptionRadioGroup } from "@/components";
import type { SubscriptionDurationInMonths } from "@/shared.types";
import { Form } from "@/ui";
import { useSubscriptionsQuery } from "../../../hooks";
import type { SubscriptionFormValues } from "../../../shared";

interface SubscriptionPlansProps {
  durationInMonths: SubscriptionDurationInMonths;
}

export const SubscriptionPlans = ({
  durationInMonths,
}: SubscriptionPlansProps) => {
  const { control } = useFormContext<SubscriptionFormValues>();

  const { data, isSuccess, isLoading } =
    useSubscriptionsQuery(durationInMonths);

  const hasPending = data?.some((plan) => plan.pending);

  return (
    <div>
      {isLoading && (
        <div className="flex w-full flex-wrap justify-center gap-4">
          {Array.from({ length: 2 }, (_, index) => `${index}`).map((i) => (
            <SubscriptionRadioGroup.ItemSkeleton
              key={`subscription-card-skeleton-${i}`}
              className="border-brown-04"
            />
          ))}
        </div>
      )}

      {isSuccess && (
        <Form.Field
          control={control}
          name="subscriptionPriceId"
          render={({ field: { value, onChange, ...rest } }) => (
            <Form.Item>
              <Form.Control>
                <SubscriptionRadioGroup.Root
                  value={value}
                  onValueChange={onChange}
                  {...rest}
                >
                  {data?.map((plan) => (
                    <SubscriptionRadioGroup.Item
                      key={plan.subscriptionPriceId}
                      value={plan.subscriptionPriceId}
                      plan={plan}
                      className="border-brown-04"
                      disabled={hasPending ? plan.pending : plan.current}
                    />
                  ))}
                </SubscriptionRadioGroup.Root>
              </Form.Control>
              <Form.Message className="text-center" />
            </Form.Item>
          )}
        />
      )}
    </div>
  );
};

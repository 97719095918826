import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { SUBSCRIPTION_STATUS } from "@/shared.constants";
import type { SubscriptionStatus } from "@/shared.types";
import { Button, DollarMinimalisticIcon, IconWrapper } from "@/ui";

interface SubscriptionActionsProps {
  status: SubscriptionStatus;
}
export const SubscriptionActions = ({ status }: SubscriptionActionsProps) => {
  const navigate = useNavigate();

  if (status === SUBSCRIPTION_STATUS.CANCELLED) {
    return (
      <Button
        onClick={() => navigate(ROUTES.MESSAGES)}
        size="lg"
        className="w-full shrink"
      >
        <IconWrapper size="md">
          <DollarMinimalisticIcon />
        </IconWrapper>
        {t`Reactivate Subscription`}
      </Button>
    );
  }

  const hasPaymentError = status === SUBSCRIPTION_STATUS.PAYMENT_FAILED;

  return (
    <>
      <Button
        disabled={hasPaymentError}
        onClick={() => navigate(ROUTES.MESSAGES)}
        size="lg"
        variant="secondary"
        className="w-full shrink"
      >
        {t`Cancel subscription`}
      </Button>

      <Button
        disabled={hasPaymentError}
        onClick={() => navigate(ROUTES.BILLING.CHANGE_SUBSCRIPTION)}
        size="lg"
        className="w-full shrink"
      >
        <IconWrapper size="md">
          <DollarMinimalisticIcon />
        </IconWrapper>
        {t`Change subscription`}
      </Button>
    </>
  );
};

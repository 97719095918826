import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { acceptRequestedPlanChange, getPatientSubscriptionPlan } from "@/api";
import { errorToast, useToastStore } from "@/ui";

export const useRequestedPlanChange = () => {
  const pushToast = useToastStore((state) => state.pushToast);

  const queryClient = useQueryClient();

  const acceptChangeMutation = useMutation({
    mutationFn: acceptRequestedPlanChange.mutation,
    onSuccess: () => {
      acceptRequestedPlanChange.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Subscription plan successfully updated!`,
      });
    },
    onError: errorToast,
  });

  const patientSubscriptionQuery = useQuery({
    ...getPatientSubscriptionPlan(),
    refetchOnWindowFocus: false,
  });

  return {
    acceptChangeMutation,
    ...patientSubscriptionQuery.data,
  };
};

import type { ComponentProps } from "react";
import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { env } from "@/env";
import { EXTERNAL_LINK } from "@/router";
import type { SubscriptionPlan } from "@/shared.types";
import { Alert, RadioGroupCards, Tooltip, VideoCameraIcon } from "@/ui";
import { tw } from "@/utils";

interface SubscriptionOptionsProps
  extends ComponentProps<typeof RadioGroupCards.Root> {
  subscriptionPlans: SubscriptionPlan[];
}

export const SubscriptionOptions = ({
  subscriptionPlans,
  ...props
}: SubscriptionOptionsProps) => {
  const FDAShortageLink = (
    <Link
      key={EXTERNAL_LINK.FDA_DRUG_SHORTAGE}
      to={EXTERNAL_LINK.FDA_DRUG_SHORTAGE}
      target="_blank"
      className="underline"
    >
      {t`had ended`}
    </Link>
  );

  return (
    <RadioGroupCards.Root className="grow" {...props}>
      {subscriptionPlans.map((plan) => {
        const isTirzepatide =
          env.VITE_STRIPE_TIRZEPATIDE_MONTHLY_PRICE_ID ===
          plan.subscriptionPriceId;

        return (
          <RadioGroupCards.Item
            asChild
            id={`radio-group-item-${plan.subscriptionPriceId}`}
            key={plan.subscriptionPriceId}
            value={plan.subscriptionPriceId}
            className={tw(isTirzepatide && "gap-2.5 pr-4")}
          >
            <div>
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-x-2 gap-y-0.5 sm:flex-row sm:items-center">
                  <RadioGroupCards.ItemLabel
                    htmlFor={`radio-group-item-${plan.subscriptionPriceId}`}
                  >
                    {plan.name}
                  </RadioGroupCards.ItemLabel>

                  {isTirzepatide && (
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger className="flex w-fit items-center gap-1.5 rounded-full bg-salmon-10 p-1 px-3 text-salmon-01 sm:p-1">
                          <VideoCameraIcon className="size-2.5" />

                          <p className="text-xs sm:hidden">{t`Requires video consultation`}</p>
                        </Tooltip.Trigger>

                        <Tooltip.Content>
                          {t`This answer will require a video consultation`}
                        </Tooltip.Content>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  )}
                </div>

                <RadioGroupCards.ItemDescription>
                  {plan.description}
                </RadioGroupCards.ItemDescription>
              </div>

              {isTirzepatide && (
                <Alert>
                  <p>
                    {jt`On October 2, 2024, the FDA determined that the shortage of Tirzepatide injection, a glucagon-like peptide 1 (GLP-1) medication, ${FDAShortageLink}.`}
                    <br />
                    <strong>
                      {t`To ensure continuity of care and avoid any lapse in your treatment, we recommend switching to compounded Semaglutide or brand name medication.`}
                    </strong>
                  </p>
                </Alert>
              )}

              <RadioGroupCards.ItemIndicator className="absolute right-4 top-4" />
            </div>
          </RadioGroupCards.Item>
        );
      })}
    </RadioGroupCards.Root>
  );
};

import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import type { MainInfoFormValues } from "@/domains/embeddables-onboarding/models";
import { useStateOptionsQuery } from "@/hooks";
import { Form, Home2Icon, Input, Loader, Select } from "@/ui";

export const LocationFormSection = () => {
  const { control } = useFormContext<MainInfoFormValues>();

  const { data: stateOptions, isLoading: isLoadingStateOptions } =
    useStateOptionsQuery();

  return (
    <div className="flex flex-col gap-4 md:p-4">
      <h3 className="text-lg font-bold text-brown-10">{t`Address`}</h3>

      <div className="grid gap-x-4 gap-y-1.5 sm:grid-cols-2">
        <Form.Field
          control={control}
          name="location.line1"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`Address *`}</Form.Label>
              <Form.Control>
                <Input
                  left={<Home2Icon />}
                  placeholder={t`E.g. 123, Main Street`}
                  {...field}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />
        <Form.Field
          control={control}
          name="location.line2"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`Apartment number`}</Form.Label>
              <Form.Control>
                <Input
                  left={<Home2Icon />}
                  placeholder={t`E.g. 1A`}
                  {...field}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />
        <Form.Field
          control={control}
          name="location.zip"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`Zip code *`}</Form.Label>
              <Form.Control>
                <Input
                  left={<Home2Icon />}
                  placeholder={t`E.g. 12345`}
                  {...field}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />
        <Form.Field
          control={control}
          name="location.city"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`City *`}</Form.Label>
              <Form.Control>
                <Input
                  left={<Home2Icon />}
                  placeholder={t`E.g. Phoenix`}
                  {...field}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />

        <Form.Field
          control={control}
          name="location.state"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`State *`}</Form.Label>
              <Form.Control>
                <Select
                  autocomplete
                  placeholder={t`E.g. New York`}
                  left={
                    isLoadingStateOptions ? <Loader size="xs" /> : <Home2Icon />
                  }
                  options={stateOptions ?? []}
                  disabled={isLoadingStateOptions}
                  {...field}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />

        <Form.Field
          control={control}
          name="location.country"
          render={({ field }) => (
            <Form.Item>
              <Form.Label>{t`Country *`}</Form.Label>
              <Form.Control>
                <Input
                  disabled
                  left={<Home2Icon />}
                  {...field}
                  className="disabled:bg-brown-03"
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />
      </div>
    </div>
  );
};

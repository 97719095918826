import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { APPOINTMENT_REQUIRED_STATES } from "@/shared.constants";
import { calculateAge, formatDOB } from "@/utils";

export const useRequiresAppointment = () => {
  const { mainInfoFormValues, patient } = useEmbeddablesForms();

  if (!mainInfoFormValues?.dob) {
    return true;
  }

  const userAge = calculateAge(formatDOB(mainInfoFormValues.dob));

  const state = patient.location?.state;

  const requiresAppointmentByAge = userAge >= 65;
  const requiresAppointmentByState =
    !state || APPOINTMENT_REQUIRED_STATES.includes(state);

  const requiresAppointment =
    requiresAppointmentByAge || requiresAppointmentByState;

  return requiresAppointment;
};

import { jt, t } from "ttag";

import { env } from "@/env";
import { useBoolean, useRequestedPlanChange } from "@/hooks";
import { Button, ConfettiFixedIconMono, Dialog } from "@/ui";
import { ChangeSubscriptionConfirmationModal } from "./ChangeSubscriptionConfirmationModal";
import { KeepCurrentSubscriptionModal } from "./KeepCurrentSubscriptionModal";

export const ChangePlanFridaysModal = () => {
  const { currentPeriodPlan, requestedPlanChange } = useRequestedPlanChange();

  const showRequestedChangeByFridays = Boolean(
    requestedPlanChange?.subscriptionPriceId ===
      env.VITE_STRIPE_BRANDED_MONTHLY_PRICE_ID,
  );

  const { value: showModal, setFalse: onClose } = useBoolean(true);
  const {
    value: showConfirmationModal,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
  } = useBoolean(false);
  const {
    value: showKeepModal,
    setTrue: openKeepModal,
    setFalse: closeKeepModal,
  } = useBoolean(false);

  return (
    <Dialog.Root
      open={showRequestedChangeByFridays && showModal}
      onOpenChange={onClose}
    >
      {currentPeriodPlan && (
        <Dialog.Content size="modal" className="h-fit">
          <div className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <ConfettiFixedIconMono color="salmon" />
              </Dialog.Icon>
              <Dialog.Title>{t`Subscription options available`}</Dialog.Title>
              <Dialog.Description>
                {jt`You're currently on a ${currentPeriodPlan.name} subscription for $${currentPeriodPlan.price}/month. Since you were recently prescribed Branded medication, you might want to switch to a subscription that matches your current needs.`}
              </Dialog.Description>
            </Dialog.Header>

            <Dialog.Footer variant="sticky">
              <Button
                onClick={openKeepModal}
                size="lg"
                variant="secondary"
              >{t`Keep Current Subscription`}</Button>
              <Button
                onClick={openConfirmationModal}
                size="lg"
              >{t`Change Subscription`}</Button>
            </Dialog.Footer>
          </div>

          <KeepCurrentSubscriptionModal
            show={showKeepModal}
            onClose={closeKeepModal}
            onConfirm={() => {
              onClose();
              closeKeepModal();
            }}
          />
          <ChangeSubscriptionConfirmationModal
            show={showConfirmationModal}
            onClose={closeConfirmationModal}
            onConfirm={() => {
              onClose();
              closeConfirmationModal();
            }}
          />
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
};

import type { ServiceResponse } from "@/api";
import { publicAPI, STRIPE_DOMAIN } from "@/api";
import type {
  SubscriptionDurationInMonths,
  SubscriptionPlan,
} from "@/shared.types";

export const getOnboardingSubscriptionPlans = (
  durationInMonths: SubscriptionDurationInMonths = "1",
) => ({
  queryKey: [STRIPE_DOMAIN, "getOnboardingSubscriptionPlans", durationInMonths],
  queryFn: async () => {
    const { data } = await publicAPI.get<ServiceResponse<SubscriptionPlan[]>>(
      `/plans`,
      { params: { durationInMonths } },
    );

    return data.data;
  },
});

import { useQueryClient } from "@tanstack/react-query";

import { getPatientSubscriptionPlan } from "@/api";
import { getSubscriptionPlans } from "@/domains/billing/api";
import type { SubscriptionDurationInMonths } from "@/shared.types";

export const usePrefetchQueries = () => {
  const queryClient = useQueryClient();

  const prefetchSubscriptionsQueries = (
    durationInMonths: SubscriptionDurationInMonths,
  ) => {
    void queryClient.prefetchQuery({
      ...getSubscriptionPlans(durationInMonths),
      staleTime: Infinity,
    });
    void queryClient.prefetchQuery({
      ...getPatientSubscriptionPlan(),
      staleTime: Infinity,
    });
  };

  return { prefetchSubscriptionsQueries };
};

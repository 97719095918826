import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { jt, t } from "ttag";

import { FullScreenLoader } from "@/components";
import { env } from "@/env";
import { ROUTES } from "@/router";
import { Alert, Button, Checkbox, Form } from "@/ui";
import { usePatientPlanQuery, useSubscriptionMutation } from "../hooks";
import type { SubscriptionFormValues } from "../shared";

export const ChangeSubscriptionFooter = () => {
  const { control, handleSubmit } = useFormContext<SubscriptionFormValues>();
  const { mutate, isPending } = useSubscriptionMutation();
  const navigate = useNavigate();
  const nextSubscriptionPlanId = useWatch({
    control,
    name: "subscriptionPriceId",
  });
  const {
    patientPlanQuery: { data: patientSubscriptions, isLoading },
  } = usePatientPlanQuery();

  const isLegacy =
    patientSubscriptions?.currentPeriodPlan?.subscriptionPriceId ===
    env.VITE_STRIPE_BASE_PRICE_ID;

  const submitDisabled = !nextSubscriptionPlanId;

  const handleChangeSubscription = handleSubmit((values) =>
    mutate(values.subscriptionPriceId, {
      onSuccess: () => navigate(ROUTES.BILLING.OVERVIEW),
    }),
  );

  const takeEffectStrong = (
    <strong key="take-effect-strong">{t`will take effect starting from your next billing period`}</strong>
  );
  const noCommitmentStrong = (
    <strong key="no-commitment-strong">{t`No commitment - cancel anytime!`}</strong>
  );

  return (
    <div className="flex flex-col items-center gap-1 pb-6 md:pb-12">
      <div className="flex flex-col items-center gap-4">
        <Alert type="warning">
          {jt`Any changes to your subscription plan ${takeEffectStrong}. This means that your current plan will remain active until the end of the current billing cycle. ${noCommitmentStrong}`}
        </Alert>

        <Form.Field
          control={control}
          name="hasConsent"
          disabled={isLoading}
          render={({ field: { value, onChange, ...rest } }) => (
            <Form.Item>
              <div className="flex gap-2.5">
                <Form.Control>
                  <Checkbox
                    checked={value}
                    onCheckedChange={onChange}
                    containerClassName="grow-0"
                    className="mt-0.5"
                    {...rest}
                  />
                </Form.Control>
                <Form.Label>
                  {isLegacy
                    ? t`I agree to the new charge and understand that I cannot revert to my current subscription`
                    : t`I agree to the new charge associated with my subscription and understand the changes in pricing`}
                </Form.Label>
              </div>
              <Form.Message compact={false} className="text-center" />
            </Form.Item>
          )}
        />
      </div>

      <Button
        disabled={submitDisabled}
        onClick={handleChangeSubscription}
        size="lg"
        className="w-fit"
      >
        {t`Change subscription`}
      </Button>

      {isPending && <FullScreenLoader />}
    </div>
  );
};

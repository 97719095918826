import { t } from "ttag";

import { DISCOUNT_DURATION, DISCOUNT_TYPE } from "@/shared.constants";
import type { Coupon } from "@/shared.types";

export const getCouponMessageFromResponse = (price: number, coupon: Coupon) => {
  const baseText = t`As a special treat `;

  const durationText = (() => {
    switch (coupon.duration) {
      case DISCOUNT_DURATION.ONCE:
        return t`for your first month `;
      case DISCOUNT_DURATION.REPEATING:
        return t`for your first ${coupon.durationInMonths} months `;
      case DISCOUNT_DURATION.FOREVER:
        return t`for the duration of your subscription `;
    }
  })();

  const priceAfterPercentageDiscount = (
    price -
    (price * coupon.percentOff) / 100
  ).toFixed(2);

  const couponDuration =
    coupon.duration === DISCOUNT_DURATION.ONCE
      ? t`for your first month`
      : t`per month`;

  const priceAfterFixedDiscount = Math.max(price - coupon.amountOff, 0);

  const discountText = (() => {
    switch (coupon.type) {
      case DISCOUNT_TYPE.PERCENTAGE:
        return t`you get ${coupon.percentOff}% off. You'll pay $${priceAfterPercentageDiscount} ${couponDuration}`;
      case DISCOUNT_TYPE.FIXED_AMOUNT:
        return t`you get $${coupon.amountOff} off. You'll pay $${priceAfterFixedDiscount} ${couponDuration}`;
    }
  })();

  const priceText = (() => {
    if (coupon.duration !== DISCOUNT_DURATION.FOREVER) {
      return t`, then $${price} per month after that.`;
    }
    return ".";
  })();

  return `${baseText}${durationText}${discountText}${priceText}`;
};

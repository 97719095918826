import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { MainInfoFormValues } from "@/domains/embeddables-onboarding/models";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { AltArrowRightIcon, Button, DateOfBirthInputs, Form } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { LocationFormSection } from "./components";
import { useMainInfoForm, useUpdatePatientMutation } from "./hooks";

export const MainInfoForm = () => {
  const {
    actions: { setMainInfoFormValues },
  } = useEmbeddablesForms();

  const form = useMainInfoForm();

  const navigate = useNavigate();

  const updateEmbeddablesPatient = useUpdatePatientMutation();

  const onSubmit: SubmitHandler<MainInfoFormValues> = (values) => {
    setMainInfoFormValues(values);

    updateEmbeddablesPatient.mutate(values, {
      onSuccess: () => navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY),
      onError: (error) => handleAxiosFieldErrors(error, form.setError),
    });
  };

  return (
    <Form.Provider {...form}>
      {updateEmbeddablesPatient.isPending && <FullScreenLoader />}
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex grow flex-col gap-8 md:px-14"
      >
        <div className="flex flex-col gap-4 pb-0 md:p-4">
          <h3 className="text-lg font-bold text-brown-10">
            {t`Date of birth`}
          </h3>
          <Form.Field
            control={form.control}
            name="dob"
            render={({ formState: { errors } }) => (
              <Form.Item>
                <Form.Label>{t`Date of birth *`}</Form.Label>
                <Form.Control>
                  <DateOfBirthInputs />
                </Form.Control>
                <Form.Message compact={false}>
                  {errors.dob?.month?.message ??
                    errors.dob?.day?.message ??
                    errors.dob?.year?.message}
                </Form.Message>
              </Form.Item>
            )}
          />
        </div>

        <LocationFormSection />

        <div className="mt-auto flex justify-end">
          <Button type="submit" size="lg" className="w-full sm:w-fit">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </form>
    </Form.Provider>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import {
  queriesStore,
  setEmbeddablesPassword,
} from "@/domains/embeddables-onboarding/api";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { authStore } from "@/stores";
import { errorToast } from "@/ui";

export const useSetEmbeddablesPassword = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  return useMutation({
    mutationFn: setEmbeddablesPassword,
    onError: errorToast,
    onSuccess: ({ accessToken }) => {
      authStore.setToken(accessToken);
      void queryClient.invalidateQueries({ queryKey: queriesStore.user._def });
      navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO);
    },
  });
};

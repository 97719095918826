import { Navigate, Route, Routes } from "react-router-dom";

import { RequireLoggedOut, ROUTES } from "@/router";
import { useAuthStore, usePortalUserStore } from "@/stores";
import {
  Account,
  ChooseJourney,
  IntakeForm,
  MainInfo,
  MedicationRequest,
  ScheduleVideoConsultation,
  SuccessScreen,
} from "../screens";
import {
  RequireEmbeddablesHealthieAccount,
  RequireFormData,
  RequireValidUserCode,
} from "./components";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "./constants";

const getPartialRoute = (route: string) => {
  return route.replace(EMBEDDABLES_ONBOARDING_ROUTE.BASE, "");
};

export const EmbeddablesOnboardingRouter = () => {
  const isLoggedIn = useAuthStore((state) => Boolean(state.token));
  const intakeFormCompleted = usePortalUserStore(({ portalUser }) =>
    Boolean(portalUser?.intakeFormCompleted),
  );

  return (
    <Routes>
      <Route
        path={`${getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.ACCOUNT)}/:userCode`}
        element={
          <RequireLoggedOut>
            <RequireValidUserCode>
              <Account />
            </RequireValidUserCode>
          </RequireLoggedOut>
        }
      />

      {isLoggedIn && !intakeFormCompleted && (
        <Route element={<RequireEmbeddablesHealthieAccount />}>
          <Route
            path={getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO)}
            element={<MainInfo />}
          />

          <Route element={<RequireFormData />}>
            <Route
              path={getPartialRoute(
                EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY,
              )}
              element={<ChooseJourney />}
            />

            <Route
              path={getPartialRoute(
                EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE,
              )}
              element={<ScheduleVideoConsultation />}
            />

            <Route
              path={getPartialRoute(
                EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_REFILL_REQUEST,
              )}
              element={<MedicationRequest />}
            />

            <Route
              path={getPartialRoute(
                EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS,
              )}
              element={<IntakeForm />}
            />
          </Route>
        </Route>
      )}

      <Route
        path={getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS)}
        element={<SuccessScreen />}
      />

      <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
    </Routes>
  );
};

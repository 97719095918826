import type { ServiceResponse } from "@/api";
import { privateAPI, STRIPE_DOMAIN } from "@/api";
import type {
  Payment,
  PaymentMethod,
  SubscriptionDurationInMonths,
  SubscriptionPlan,
} from "@/shared.types";

export const getSubscriptionPlans = (
  durationInMonths: SubscriptionDurationInMonths = "1",
) => ({
  queryKey: [STRIPE_DOMAIN, "getSubscriptionPlans", durationInMonths],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<SubscriptionPlan[]>>(
      `/plans`,
      { params: { durationInMonths } },
    );

    return data.data;
  },
});

export const getPaymentHistoryQuery = (lastPayment: string | undefined) => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentHistoryQuery", lastPayment],
  queryFn: async () => {
    const response = await privateAPI.get<ServiceResponse<Payment[]>>(
      `/patients/payment-information`,
      { params: { startAfter: lastPayment } },
    );

    return response.data;
  },
});

export const getPaymentMethodQuery = () => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentMethodQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<PaymentMethod>>(
      `/patients/payment-method`,
    );

    return data.data;
  },
});

import type { QueryClient } from "@tanstack/react-query";

import type { ServiceResponse } from "@/api";
import { getPatientSubscriptionPlan, privateAPI } from "@/api";
import type { SubscriptionPlan } from "@/shared.types";

export const changeSubscription = {
  mutation: async (
    subscriptionPriceId: SubscriptionPlan["subscriptionPriceId"],
  ) => {
    const { data } = await privateAPI.put<ServiceResponse<null>>(
      "/patients/plans",
      { subscriptionPriceId },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: getPatientSubscriptionPlan().queryKey,
    });
  },
};

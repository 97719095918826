import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { MainInfoFormValues } from "@/domains/embeddables-onboarding/models";
import { getMainInfoFormValuesSchema } from "@/domains/embeddables-onboarding/models";
import { parseDOB } from "@/utils";

export const useMainInfoForm = () => {
  const { patient, mainInfoFormValues } = useEmbeddablesForms();

  return useForm<MainInfoFormValues>({
    resolver: zodResolver(getMainInfoFormValuesSchema()),
    defaultValues: mainInfoFormValues ?? {
      dob: patient.dateOfBirth && parseDOB(patient.dateOfBirth),
      location: { ...patient.location, country: "US" },
    },
  });
};

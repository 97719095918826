import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import Markdown from "react-markdown";
import { tv } from "tailwind-variants";
import { jt, t } from "ttag";

import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type { SubscriptionPlan } from "@/shared.types";
import { Badge, ClockIcon, Label, RadioIndicator, StarIcon } from "@/ui";
import { tw } from "@/utils";

const subscriptionItemVariance = tv({
  slots: {
    base: "flex max-w-subscription-card flex-col overflow-hidden rounded-lg border border-transparent bg-white text-brown-08",
    radioItem:
      "cursor-pointer disabled:cursor-not-allowed disabled:opacity-70 data-[state=checked]:border-nature-07 data-[state=checked]:bg-nature-02 data-[state=checked]:text-nature-08 [&_.discount-alert]:data-[state=checked]:bg-nature-03 [&_.discount-alert_strong]:data-[state=checked]:text-nature-10 [&_em]:data-[state=checked]:text-nature-06 [&_label]:data-[state=checked]:text-nature-08",
    thumbnail: "size-full h-60 bg-salmon-01 object-cover",
    container: "relative flex flex-col items-start gap-2 p-6",
    compound: "-mb-1 font-bold text-nature-09 md:h-5",
    name: "text-left text-2xl font-bold text-nature-10",
    description: "text-left text-sm text-brown-08 md:min-h-5",
    previousPrice: "pr-2 text-3xl font-bold text-brown-06 line-through",
    price: "text-5xl text-brown-10",
    billingPeriod: "text-sm",
    disclaimer: "text-sm text-brown-10",
    list: "list-inside list-disc pl-1.5 text-left text-sm [&_em]:not-italic [&_em]:text-brown-06",
    discountAlert:
      "discount-alert rounded-2xl bg-brown-03 p-3 text-left text-sm [&>strong]:text-yellow-10",
  },
  variants: {
    hasDurationInMonths: {
      true: {
        billingPeriod: "block",
      },
    },
    hasDiscount: {
      false: {
        disclaimer: "-translate-y-1",
      },
    },
  },
});

interface SubscriptionPlanWithStatus extends SubscriptionPlan {
  pending?: boolean;
  current?: boolean;
}

interface SubscriptionItemProps
  extends ComponentPropsWithoutRef<typeof RadioGroup.Item> {
  plan: SubscriptionPlanWithStatus;
  pending?: boolean;
}

export const SubscriptionItem = forwardRef<
  ElementRef<typeof RadioGroup.Item>,
  SubscriptionItemProps
>(({ plan, className, ...props }, ref) => {
  const hasDurationInMonths = Boolean(plan.discount?.durationInMonths);
  const hasDiscount = Boolean(plan.discount);
  const {
    base,
    radioItem,
    thumbnail,
    container,
    compound,
    name,
    description,
    previousPrice,
    price,
    billingPeriod,
    disclaimer,
    list,
    discountAlert,
  } = subscriptionItemVariance({ hasDurationInMonths, hasDiscount });

  const durationInMonthsPeriod = plan.discount?.durationInMonths
    ? jt`for ${plan.discount.durationInMonths} month(s)`
    : "";
  const durationInMonths = plan.discount?.durationInMonths
    ? `${plan.discount.durationInMonths} `
    : "";
  const monthsDuration =
    plan.durationInMonths == SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY
      ? t`month`
      : jt`${plan.durationInMonths} months`;
  const planPrice = (
    <strong key={`${plan.subscriptionPriceId}-initial-price`}>
      ${plan.price}
    </strong>
  );

  return (
    <RadioGroup.Item
      ref={ref}
      className={tw(base({ className }), radioItem())}
      {...props}
    >
      <img
        src={plan.thumbnail}
        alt={`${plan.name} Thumbnail`}
        className={thumbnail()}
        draggable="false"
      />

      <div className={container()}>
        <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2">
          {plan.pending && (
            <Badge
              variant="secondary"
              className="items-center gap-1 border-yellow-05 bg-yellow-04 font-medium text-brown-10"
            >
              <ClockIcon className="size-3" />
              {t`Pending`}
            </Badge>
          )}

          {plan.current && (
            <Badge
              variant="secondary"
              className="items-center gap-1 border-salmon-09 bg-salmon-09 font-medium text-brown-01"
            >
              <StarIcon className="size-3" />
              {t`Current subscription`}
            </Badge>
          )}
        </div>

        <p className={compound()}>{plan.isCompound && t`Compounded`}</p>

        <div className="flex w-full justify-between gap-4">
          <h4 className={name()}>{plan.name}</h4>

          <RadioIndicator className="mt-1.5" size="md" />
        </div>

        <p className={description()}>{plan.description}</p>

        <p>
          {plan.discount && (
            <span className={previousPrice()}>${plan.price}</span>
          )}

          <strong className={price()}>
            ${plan.discount?.priceAfterDiscount ?? plan.price}
          </strong>

          <span className={billingPeriod()}>
            {" "}
            / {monthsDuration} {durationInMonthsPeriod}
          </span>
        </p>

        <p className={disclaimer()}>{plan.disclaimer}</p>

        {plan.discount && (
          <div className={discountAlert()}>
            {jt`After the first ${durationInMonths}month(s), your subscription will change to ${planPrice} every ${monthsDuration}`}
          </div>
        )}

        <div className="flex grow flex-col items-start">
          <Label
            size="sm"
            htmlFor={`${plan.subscriptionPriceId}-features`}
          >{t`Includes:`}</Label>
          <Markdown
            components={{
              ul: (props) => (
                <ul
                  id={`${plan.subscriptionPriceId}-features`}
                  {...props}
                  className={list()}
                />
              ),
            }}
          >
            {plan.features.map((feature) => `- ${feature}`).join("\n")}
          </Markdown>
        </div>
      </div>
    </RadioGroup.Item>
  );
});
SubscriptionItem.displayName = "SubscriptionPlanCard.Item";

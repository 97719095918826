import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import { getUserValidation } from "./user";

export const queriesStore = createQueryKeyStore({
  user: {
    validation: (userCode?: string) => ({
      queryKey: [userCode],
      queryFn: userCode
        ? () => getUserValidation(userCode)
        : (skipToken as never),
    }),
  },
});

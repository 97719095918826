import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { ChooseJourneyBackground } from "./components";
import { useRequiresAppointment } from "./hooks";
import { JourneyRestricted, JourneySelection } from "./sections";

export const ChooseJourney = () => {
  const appointmentRequired = useRequiresAppointment();

  return (
    <EmbeddablesOnboardingScreenLayout
      variant="secondary"
      background={<ChooseJourneyBackground />}
    >
      {appointmentRequired ? <JourneyRestricted /> : <JourneySelection />}
    </EmbeddablesOnboardingScreenLayout>
  );
};

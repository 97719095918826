import type { SVGProps } from "@/shared.types";

export const PillsFixedIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 75 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <rect
      x="14.6189"
      y="1.48633"
      width="60"
      height="60"
      rx="30"
      transform="rotate(9.40703 14.6189 1.48633)"
      fill="#FFDFCD"
    />
    <circle cx="45.4952" cy="26.6696" r="6.18323" fill="#E3B79E" />
    <rect
      x="48.7175"
      y="38.9883"
      width="15.8827"
      height="29.7259"
      rx="7.94135"
      transform="rotate(45 48.7175 38.9883)"
      fill="#E3B79E"
    />
    <rect
      x="18.9084"
      y="21.9824"
      width="21.0784"
      height="22.5648"
      rx="10.5392"
      transform="rotate(45 18.9084 21.9824)"
      fill="#E3B79E"
    />
    <path
      fillRule="evenodd"
      d="M51.5044 19.7831C47.83 16.1088 41.8728 16.1088 38.1984 19.7831C36.3616 21.6199 35.4427 24.0311 35.4427 26.4361C35.4427 28.8411 36.3616 31.2522 38.1984 33.089C41.8728 36.7634 47.83 36.7634 51.5044 33.089C53.3412 31.2522 54.2601 28.8411 54.2601 26.4361C54.2601 24.0311 53.3412 21.6199 51.5044 19.7831ZM40.8596 22.4443C43.0642 20.2397 46.6386 20.2397 48.8432 22.4443C49.9458 23.5469 50.4966 24.9884 50.4966 26.4361C50.4966 27.8837 49.9458 29.3252 48.8432 30.4278C46.6386 32.6324 43.0642 32.6324 40.8596 30.4278C39.7569 29.3252 39.2062 27.8837 39.2062 26.4361C39.2062 24.9884 39.7569 23.547 40.8596 22.4443Z"
      fill="#8C6955"
    />
    <path
      fillRule="evenodd"
      d="M28.1255 28.4118C22.9815 23.2677 14.6413 23.2677 9.49721 28.4118C6.92554 30.9834 5.63916 34.3578 5.63916 37.7259C5.63916 41.0941 6.92554 44.4684 9.49721 47.0401C14.6413 52.1841 22.9815 52.1841 28.1255 47.0401C30.6972 44.4684 31.9836 41.0941 31.9836 37.7259C31.9836 34.3578 30.6972 30.9834 28.1255 28.4118ZM12.1584 31.0729C15.8327 27.3986 21.79 27.3986 25.4643 31.0729C26.8209 32.4295 27.6764 34.0951 28.0313 35.8442H9.59139C9.94631 34.0951 10.8018 32.4295 12.1584 31.0729ZM9.59139 39.6077H28.0313C27.6764 41.3567 26.8209 43.0223 25.4643 44.3789C21.79 48.0532 15.8327 48.0532 12.1584 44.3789C10.8018 43.0223 9.94631 41.3567 9.59139 39.6077Z"
      fill="#8C6955"
    />
    <path
      fillRule="evenodd"
      d="M56.2845 42.9057C51.8871 38.5083 44.7575 38.5083 40.3602 42.9057L31.5181 51.7478C27.1207 56.1451 27.1207 63.2747 31.5181 67.672C35.9155 72.0694 43.045 72.0694 47.4424 67.672L56.2845 58.83C60.6818 54.4326 60.6818 47.3031 56.2845 42.9057ZM43.0214 45.5669C45.949 42.6392 50.6956 42.6392 53.6233 45.5669C56.5509 48.4945 56.5509 53.2411 53.6233 56.1688L50.1765 59.6155C50.1553 59.609 50.1331 59.602 50.1102 59.5947C49.7802 59.4893 49.277 59.3051 48.6489 59.0024C47.3948 58.3981 45.6354 57.318 43.7541 55.4367C41.8728 53.5553 40.7925 51.7958 40.188 50.5415C39.8852 49.9133 39.701 49.41 39.5955 49.0799C39.5882 49.057 39.5812 49.0348 39.5747 49.0136L43.0214 45.5669ZM36.6743 51.9139C36.7138 51.9995 36.7549 52.0867 36.7977 52.1755C37.5683 53.7744 38.8854 55.8903 41.0929 58.0978C43.3005 60.3054 45.4162 61.6223 47.015 62.3928C47.1037 62.4355 47.1908 62.4765 47.2761 62.5159L44.7812 65.0108C41.8536 67.9385 37.1069 67.9385 34.1793 65.0108C31.2517 62.0832 31.2517 57.3366 34.1793 54.4089L36.6743 51.9139Z"
      fill="#8C6955"
    />
  </svg>
);

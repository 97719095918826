import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useRequestedPlanChange } from "@/hooks";
import type { ModalProps } from "@/shared.types";
import {
  Button,
  DetailsCard,
  Dialog,
  IconWrapper,
  PillIcon,
  ShieldWarningIconMono,
} from "@/ui";

interface ConfirmationModalProps extends ModalProps {
  onConfirm: () => void;
}

export const ChangeSubscriptionConfirmationModal = ({
  show,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const { acceptChangeMutation, requestedPlanChange } =
    useRequestedPlanChange();

  const handleConfirmPlanChange = () => {
    acceptChangeMutation.mutate(true, { onSuccess: onConfirm });
  };

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <ShieldWarningIconMono />
            </Dialog.Icon>

            <Dialog.Title>{t`Changing subscription`}</Dialog.Title>
            <Dialog.Description>
              {t`You're about to change your subscription to receive the necessary medication. Are you sure?`}
            </Dialog.Description>
          </Dialog.Header>

          <DetailsCard.Root>
            {requestedPlanChange && (
              <DetailsCard.Item>
                <DetailsCard.Label>
                  <IconWrapper size="sm" as="span">
                    <PillIcon />
                  </IconWrapper>
                  {t`Changing to:`}
                </DetailsCard.Label>

                <DetailsCard.Description>
                  {requestedPlanChange.name}
                  <DetailsCard.Separator />${requestedPlanChange.price}
                  {t`month`}
                </DetailsCard.Description>
              </DetailsCard.Item>
            )}
          </DetailsCard.Root>

          <Dialog.Footer>
            <Button
              onClick={onClose}
              size="lg"
              variant="secondary"
              className="hidden sm:block"
            >
              {t`Go back`}
            </Button>

            <Button size="lg" onClick={handleConfirmPlanChange}>
              {t`Confirm change`}
            </Button>
          </Dialog.Footer>
        </div>

        {acceptChangeMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};

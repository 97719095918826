import type { QueryClient } from "@tanstack/react-query";

import type { Coupon, PatientSubscription } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

export const STRIPE_DOMAIN = "stripe";

interface CreateStripeSubscriptionParams {
  userId: number;
  couponCode: string | null;
  subscriptionPriceId: string;
}

export const createStripeSubscription = {
  mutation: async ({
    userId,
    couponCode,
    subscriptionPriceId,
  }: CreateStripeSubscriptionParams) => {
    const { data } = await publicAPI.post<
      ServiceResponse<{ subscriptionId: string; clientSecret: string }>
    >(`/patients/${userId}/create-subscription`, {
      couponCode,
      subscriptionPriceId,
    });

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [STRIPE_DOMAIN] });
  },
};

export const updatePaymentMethod = {
  mutation: async (params: { paymentMethodId?: string; userId?: number }) => {
    const response = await privateAPI.put<ServiceResponse<void>>(
      `/patients/payment-method`,
      params,
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [STRIPE_DOMAIN] });
  },
};

export const scheduleSubscription = {
  mutation: async ({ paymentMethodId }: { paymentMethodId?: string }) => {
    const response = await privateAPI.post<ServiceResponse<void>>(
      `/patients/schedule-subscription`,
      { paymentMethodId },
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: ["patient"] });
  },
};

export const applyCode = {
  mutation: async (couponCode?: string) => {
    const {
      data: { data },
    } = await publicAPI.post<ServiceResponse<Coupon>>(`/validate-coupon-code`, {
      couponCode,
    });

    return data;
  },
};

export const getPatientSubscriptionPlan = () => ({
  queryKey: [STRIPE_DOMAIN, "getPatientSubscriptionPlan"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<PatientSubscription>>(
        `/patients/plans`,
      );

    return data.data;
  },
});

export const acceptRequestedPlanChange = {
  mutation: async (accepted: boolean) => {
    const { data } = await privateAPI.put<ServiceResponse<null>>(
      "/patients/plans/requested-plan-change",
      { accepted },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: getPatientSubscriptionPlan().queryKey,
    });
  },
};

import { useQuery } from "@tanstack/react-query";

import { getSubscriptionPlans } from "@/domains/billing/api";
import type { SubscriptionDurationInMonths } from "@/shared.types";
import { usePatientPlanQuery } from "./usePatientPlanQuery";

export const useSubscriptionsQuery = (
  durationInMonths: SubscriptionDurationInMonths,
) => {
  const { patientPlanQuery } = usePatientPlanQuery();

  const {
    isLoading: isLoadingSubscriptionPlans,
    isSuccess: isSuccessSubscriptionPlans,
    ...query
  } = useQuery({
    ...getSubscriptionPlans(durationInMonths),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: patientPlanQuery.isSuccess,
    select: (subscriptions) =>
      subscriptions.map((plan) => ({
        ...plan,
        pending:
          plan.subscriptionPriceId ===
          patientPlanQuery.data?.nextPeriodPlan?.subscriptionPriceId,
        current:
          plan.subscriptionPriceId ===
          patientPlanQuery.data?.currentPeriodPlan?.subscriptionPriceId,
      })),
  });

  const isLoading = isLoadingSubscriptionPlans || patientPlanQuery.isLoading;

  const isSuccess =
    isSuccessSubscriptionPlans && patientPlanQuery.isSuccess && !isLoading;

  return {
    ...query,
    isLoading,
    isSuccess,
  };
};

import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { LoadingScreen } from "@/components";
import { usePatientQuery } from "@/hooks";
import type { Patient } from "@/shared.types";
import type {
  ChooseJourneyFormValues,
  MainInfoFormValues,
  ScheduleVideoConsultationValues,
} from "../models";

interface EmbeddablesForms {
  patient: Patient;
  mainInfoFormValues?: MainInfoFormValues;
  chooseJourneyFormValues?: ChooseJourneyFormValues;
  scheduleVideoConsultationFormValues?: ScheduleVideoConsultationValues;

  actions: {
    setMainInfoFormValues: Dispatch<
      SetStateAction<MainInfoFormValues | undefined>
    >;
    setChooseJourneyFormValues: Dispatch<
      SetStateAction<ChooseJourneyFormValues | undefined>
    >;
    setScheduleVideoConsultationFormValues: Dispatch<
      SetStateAction<ScheduleVideoConsultationValues | undefined>
    >;
  };
}

export const EmbeddablesFormsContext = createContext<EmbeddablesForms | null>(
  null,
);

export const EmbeddablesFormContextProvider = () => {
  const [mainInfoFormValues, setMainInfoFormValues] =
    useState<MainInfoFormValues>();
  const [chooseJourneyFormValues, setChooseJourneyFormValues] =
    useState<ChooseJourneyFormValues>();
  const [
    scheduleVideoConsultationFormValues,
    setScheduleVideoConsultationFormValues,
  ] = useState<ScheduleVideoConsultationValues>();

  const { data: patient, isLoading, isSuccess } = usePatientQuery();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isSuccess) {
    return (
      <EmbeddablesFormsContext.Provider
        value={{
          patient,
          mainInfoFormValues,
          chooseJourneyFormValues,
          scheduleVideoConsultationFormValues,

          actions: {
            setMainInfoFormValues,
            setChooseJourneyFormValues,
            setScheduleVideoConsultationFormValues,
          },
        }}
      >
        <Outlet />
      </EmbeddablesFormsContext.Provider>
    );
  }

  return null;
};

export const useEmbeddablesForms = () => {
  const context = useContext(EmbeddablesFormsContext);

  if (!context) {
    throw new Error(
      "useEmbeddablesForms must be used within a <EmbeddablesFormsContext.Provider />",
    );
  }

  return context;
};

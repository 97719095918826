import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useScheduleConsultationContext } from "@/contexts";
import { useBoolean } from "@/hooks";
import { Button } from "@/ui";
import { ErrorAlert, SuccessAlert } from "./components";
import { useScheduleAppointmentMutation } from "./hooks";

export const Submit = () => {
  const {
    value: showErrorModal,
    setFalse: closeErrorModal,
    setTrue: openErrorModal,
  } = useBoolean(false);
  const { value: showSuccessModal, setTrue: openSuccessModal } =
    useBoolean(false);

  const {
    language,
    scheduleDate: { timeSlot },
    actions: { setProvider },
  } = useScheduleConsultationContext();

  const { mutate, isPending } = useScheduleAppointmentMutation();

  const handleSubmit = () => {
    if (timeSlot) {
      mutate(
        { timeSlot, language },
        {
          onError: openErrorModal,
          onSuccess: (appointment) => {
            setProvider(appointment.provider);
            openSuccessModal();
          },
        },
      );
    }
  };

  return (
    <>
      <Button
        disabled={!timeSlot || isPending}
        size="lg"
        className="w-full"
        onClick={handleSubmit}
      >
        {t`Confirm Consultation`}
      </Button>

      {isPending && <FullScreenLoader />}

      <ErrorAlert show={showErrorModal} onClose={closeErrorModal} />

      <SuccessAlert show={showSuccessModal} />
    </>
  );
};

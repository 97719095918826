import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader, HealthieForm, OnboardingHeader } from "@/components";
import { useScheduleAppointmentMutation } from "@/domains/consultations/screens/Schedule/sections/Submit/hooks";
import { EmbeddablesOnboardingScreenLayout } from "@/domains/embeddables-onboarding/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { HEALTHIE_FORM_TYPE, JOURNEY } from "@/shared.constants";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

const formType = {
  [JOURNEY.MEDICATION_REQUEST]:
    HEALTHIE_FORM_TYPE.EMBEDDABLES_ONBOARDING_REFILL,
  [JOURNEY.VIDEO_CONSULTATION]:
    HEALTHIE_FORM_TYPE.EMBEDDABLES_ONBOARDING_VIDEO_CONSULTATION,
} as const;

export const IntakeForm = () => {
  const {
    patient,
    chooseJourneyFormValues,
    scheduleVideoConsultationFormValues,
  } = useEmbeddablesForms();

  const navigate = useNavigate();

  const setAppointmentMutation = useScheduleAppointmentMutation();

  if (!chooseJourneyFormValues) {
    return null;
  }

  const handleBack = () => {
    if (chooseJourneyFormValues.journey === JOURNEY.VIDEO_CONSULTATION) {
      return navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE);
    }
    navigate(EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_REFILL_REQUEST);
  };

  const goToSuccessScreen = () =>
    navigate(EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS);

  const onSubmit = () => {
    if (scheduleVideoConsultationFormValues) {
      return setAppointmentMutation.mutate(
        scheduleVideoConsultationFormValues,
        {
          onSettled: goToSuccessScreen,
        },
      );
    }

    goToSuccessScreen();
  };

  return (
    <EmbeddablesOnboardingScreenLayout>
      <OnboardingHeader
        title={t`Intake form questions`}
        description={t`(*) Mandatory field`}
      />

      <HealthieForm
        onSubmit={onSubmit}
        patientId={patient.id}
        formType={formType[chooseJourneyFormValues.journey]}
        className="self-center"
      >
        <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
          <Button
            onClick={handleBack}
            variant="secondary"
            size="lg"
            className="w-full sm:w-fit"
          >
            <AltArrowLeftIcon className="hidden size-4 sm:block" />
            {t`Back`}
          </Button>

          <Button type="submit" size="lg" className="w-full sm:w-fit">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </HealthieForm>

      {setAppointmentMutation.isPending && <FullScreenLoader />}
    </EmbeddablesOnboardingScreenLayout>
  );
};

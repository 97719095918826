import { useEffect } from "react";
import { useShallow } from "zustand/shallow";

import { SubscriptionRadioGroup } from "@/components";
import { env } from "@/env";
import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type {
  SubscriptionDurationInMonths,
  SubscriptionPlan,
} from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { useSubscriptionsQuery } from "../hooks";

interface SubscriptionPlansProps {
  durationInMonths: SubscriptionDurationInMonths;
}

export const SubscriptionPlans = ({
  durationInMonths,
}: SubscriptionPlansProps) => {
  const [subscriptionPlan, setSubscriptionPlan] = useOnboardingStore(
    useShallow((state) => [state.subscriptionPlan, state.setSubscriptionPlan]),
  );

  const { data, isSuccess, isLoading } =
    useSubscriptionsQuery(durationInMonths);

  const handleValueChange = (
    subscriptionPriceId: SubscriptionPlan["subscriptionPriceId"],
  ) => {
    const updatedPlan = data?.find(
      (plan) => plan.subscriptionPriceId === subscriptionPriceId,
    );

    if (updatedPlan) {
      setSubscriptionPlan(updatedPlan);
    }
  };

  useEffect(() => {
    const hasNoPlanSelected = !subscriptionPlan && data;

    if (hasNoPlanSelected) {
      switch (durationInMonths) {
        case SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY:
          return handleValueChange(
            env.VITE_STRIPE_SEMAGLUTIDE_MONTHLY_PRICE_ID,
          );
        case SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY:
          return handleValueChange(
            env.VITE_STRIPE_SEMAGLUTIDE_QUARTERLY_PRICE_ID,
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SubscriptionRadioGroup.Root
      value={subscriptionPlan?.subscriptionPriceId}
      onValueChange={handleValueChange}
    >
      {isLoading &&
        Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
          <SubscriptionRadioGroup.ItemSkeleton
            key={`subscription-card-skeleton-${i}`}
          />
        ))}

      {isSuccess &&
        data.map((plan) => (
          <SubscriptionRadioGroup.Item
            key={plan.subscriptionPriceId}
            value={plan.subscriptionPriceId}
            plan={plan}
          />
        ))}
    </SubscriptionRadioGroup.Root>
  );
};

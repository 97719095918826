import { t } from "ttag";

import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import { Tabs } from "@/ui";
import { usePrefetchQueries } from "../../../hooks";

export const TabList = () => {
  const { prefetchSubscriptionsQueries } = usePrefetchQueries();

  return (
    <Tabs.List>
      <Tabs.Trigger
        value={SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY}
        className="flex-col"
        onMouseEnter={() =>
          prefetchSubscriptionsQueries(SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY)
        }
      >
        <h3 className="text-sm font-medium">{t`Monthly`}</h3>
        <p className="hidden text-xs lg:block">{t`Get a 1 month supply`}</p>
      </Tabs.Trigger>

      <Tabs.Trigger
        value={SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY}
        className="flex-col"
        onMouseEnter={() =>
          prefetchSubscriptionsQueries(
            SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY,
          )
        }
      >
        <h3 className="text-sm font-medium">{t`Bundle`}</h3>
        <p className="hidden text-xs lg:block">
          {t`Cover all 3 months of doses with a single order`}
        </p>
      </Tabs.Trigger>
    </Tabs.List>
  );
};

import type { PropsWithChildren, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { ScrollArea } from "@/ui";
import { EmbeddablesOnboardingProgressBar } from "./components";

const onboardingLayoutVariance = tv({
  slots: {
    base: "h-dvh bg-brown-02",
    container:
      "mx-auto flex h-full max-w-screen-xl flex-col gap-7 p-6 pb-0 md:p-20 md:pb-0",
    content: "flex grow flex-col gap-8 pb-10 md:pb-20",
  },
  variants: {
    variant: {
      secondary: { base: "z-10 bg-nature-08", content: "pt-4" },
    },
  },
});

type OnboardingLayoutVariance = VariantProps<typeof onboardingLayoutVariance>;

interface OnboardingScreenLayoutProps
  extends PropsWithChildren,
    OnboardingLayoutVariance {
  className?: string;
  background?: ReactNode;
}

export const EmbeddablesOnboardingScreenLayout = ({
  children,
  className,
  variant,
  background,
}: OnboardingScreenLayoutProps) => {
  const { base, container, content } = onboardingLayoutVariance({ variant });
  return (
    <ScrollArea className={base()}>
      <div className={container()}>
        {background}

        <EmbeddablesOnboardingProgressBar variant={variant} />

        {children ? (
          <main className={content({ className })}>{children}</main>
        ) : (
          <Outlet />
        )}
      </div>
    </ScrollArea>
  );
};

import { t } from "ttag";

import {
  DISCOUNT_DURATION,
  SUBSCRIPTION_CANCELLATION_EMAIL,
} from "@/shared.constants";
import type { Coupon } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { Alert, LightbulbIcon } from "@/ui";
import { getFinalChargedAmount } from "@/utils";

interface CouponDetailsProps {
  coupon?: Coupon;
}

export const PaymentDetails = ({ coupon }: CouponDetailsProps) => {
  const subscriptionPrice =
    useOnboardingStore((state) => state.subscriptionPlan?.price) ?? 0;
  if (
    coupon?.duration === DISCOUNT_DURATION.FOREVER &&
    (coupon?.percentOff === 100 || coupon?.amountOff >= subscriptionPrice)
  ) {
    return null;
  }

  const getPriceText = () => {
    if (!coupon?.isValid) {
      return t`You will be billed $${subscriptionPrice}, plus applicable taxes, today and every 28 days thereafter until you cancel.`;
    }

    const finalChangedAmount = getFinalChargedAmount(subscriptionPrice, coupon);
    switch (coupon.duration) {
      case DISCOUNT_DURATION.FOREVER:
        return t`You will be billed $${finalChangedAmount}, plus applicable taxes, every 28 days thereafter until you cancel.`;

      case DISCOUNT_DURATION.REPEATING:
        return t`You will be billed $${finalChangedAmount}, plus applicable taxes, for your Fridays subscription covering the first ${coupon.durationInMonths} month(s). After this period, you will be charged $${subscriptionPrice}, plus applicable taxes, every 28 days until you cancel.`;

      default:
        return t`You will be billed $${finalChangedAmount}, plus applicable taxes, for your Fridays subscription today and $${subscriptionPrice}, plus applicable taxes, every 28 days thereafter until you cancel.`;
    }
  };

  return (
    <Alert
      variant="outlined"
      customIcon={<LightbulbIcon />}
      className="text-brown-07"
    >
      <h4 className="text-brown-10">{t`By selecting “Pay”, you agree to the following:`}</h4>
      <ul className="list-inside list-disc pl-2">
        <li>{getPriceText()}</li>
        <li>{t`Charges will appear as "Fridays," "Fridays Health," or "Thrive Health Group Inc." on your bank statement.`}</li>
        <li>{t`No refunds or credits for partial subscription periods are given unless required by law.`}</li>
        <li>
          {t`You can cancel at any time by contacting `}
          <a
            href={`mailto:${SUBSCRIPTION_CANCELLATION_EMAIL}`}
            className="text-nature-08"
          >
            {SUBSCRIPTION_CANCELLATION_EMAIL}
          </a>
        </li>
      </ul>
    </Alert>
  );
};

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getSubscriptionPlans } from "@/domains/billing/api";

export const useSubscriptionPlansQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["subscription-plans"],
    queryFn: async () =>
      await queryClient.ensureQueryData(getSubscriptionPlans("1")),
  });
};

import { useQuery } from "@tanstack/react-query";

import { getPatientSubscriptionPlan } from "@/api";
import type { SubscriptionFormValues } from "../shared";

export const usePatientPlanQuery = () => {
  const patientPlanQuery = useQuery({
    ...getPatientSubscriptionPlan(),
    staleTime: Infinity,
  });

  const patientSubscription = patientPlanQuery.data;

  const formValues = patientSubscription?.nextPeriodPlan
    ? {
        values: {
          subscriptionPriceId:
            patientSubscription.nextPeriodPlan.subscriptionPriceId,
          hasConsent: false,
        } as SubscriptionFormValues,
      }
    : {};

  return {
    patientPlanQuery,
    formValues,
  };
};

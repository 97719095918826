import { useQuery } from "@tanstack/react-query";

import { getOnboardingSubscriptionPlans } from "@/domains/onboarding/api";
import type { SubscriptionDurationInMonths } from "@/shared.types";

export const useSubscriptionsQuery = (
  durationInMonths: SubscriptionDurationInMonths,
) => {
  return useQuery({
    ...getOnboardingSubscriptionPlans(durationInMonths),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

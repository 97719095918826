import { useState } from "react";
import { t } from "ttag";

import { SUBSCRIPTION_DURATION_IN_MONTHS } from "@/shared.constants";
import type { SubscriptionDurationInMonths } from "@/shared.types";
import { Tabs } from "@/ui";
import { CurrentRequestAlert, SubscriptionPlans, TabList } from "./components";

export const ChangeSubscriptionContent = () => {
  const [tabValue, setTabValue] = useState<SubscriptionDurationInMonths>(
    SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY,
  );

  const handleOnTabChange = (value: string) => {
    setTabValue(value as SubscriptionDurationInMonths);
  };

  return (
    <Tabs.Root
      value={tabValue}
      onValueChange={handleOnTabChange}
      className="flex grow flex-col gap-6"
    >
      <TabList />

      <CurrentRequestAlert />

      <div className="grow pt-0 lg:pb-6">
        <Tabs.Content
          value={SUBSCRIPTION_DURATION_IN_MONTHS.MONTHLY}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">{t`Get a 1 month supply`}</p>
          <SubscriptionPlans durationInMonths={tabValue} />
        </Tabs.Content>

        <Tabs.Content
          value={SUBSCRIPTION_DURATION_IN_MONTHS.QUARTERLY}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">
            {t`Cover all 3 months of doses with a single order`}
          </p>
          <SubscriptionPlans durationInMonths={tabValue} />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};

import { useQueryClient } from "@tanstack/react-query";

import { getOnboardingSubscriptionPlans } from "@/domains/onboarding/api";
import type { SubscriptionDurationInMonths } from "@/shared.types";

export const usePrefetchSubscriptions = () => {
  const queryClient = useQueryClient();

  return (durationInMonths: SubscriptionDurationInMonths) => {
    void queryClient.prefetchQuery({
      ...getOnboardingSubscriptionPlans(durationInMonths),
      staleTime: Infinity,
    });
  };
};

import { t } from "ttag";
import { z } from "zod";

import {
  ACCEPTED_IMAGE_TYPES,
  DOCUMENT_FOLDER,
  JOURNEY,
  MAX_NOTE_CHARACTERS,
  MAX_UPLOAD_SIZE,
  NOW,
} from "./shared.constants";
import { getEnumValues, isValidDOB } from "./utils";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
);

export const getDateOfBirthSchema = () =>
  z
    .object({
      day: z
        .number()
        .int()
        .min(1, { message: t`Invalid day` })
        .max(31, { message: "Invalid day" }),
      month: z
        .number()
        .int()
        .min(1, { message: t`Invalid month` })
        .max(12, { message: t`Invalid month` }),
      year: z
        .number()
        .int()
        .min(1900, { message: t`Invalid year` })
        .max(NOW.getFullYear(), { message: t`Invalid year` }),
    })
    .refine(
      (dob) => {
        if (!dob.day || !dob.month || !dob.year) {
          return true;
        }

        return isValidDOB(dob);
      },
      {
        message: t`Invalid day for the selected month`,
        path: ["day"],
      },
    );

export const getLocationSchema = () =>
  z.object({
    city: z.string().min(1, { message: t`City code is required` }),
    state: z.string().min(1, { message: t`State is required` }),
    zip: z.string().min(1, { message: t`Zip code is required` }),
    country: z.string().min(1, { message: t`Country is required` }),
    line1: z
      .string()
      .min(1, { message: t`House and street number are required` }),
    line2: z.string().optional(),
  });

const policySchema = z.object({
  cardFrontImage: z.string().optional(),
  cardBackImage: z.string().optional(),
  cardFrontId: z.string().optional().nullable(),
  cardBackId: z.string().optional().nullable(),
});

export const getPasswordSchema = () =>
  z.object({
    password: z.string().min(1, { message: t`Password is required` }),
  });

export const getEmailSchema = () =>
  z.object({
    email: z
      .string()
      .min(1, { message: t`Your email is required` })
      .email(t`Please enter a valid email`),
  });

export const getConfirmPasswordSchema = () =>
  getPasswordSchema()
    .merge(
      z.object({
        passwordConfirmation: z.string(),
      }),
    )
    .refine((data) => data.password === data.passwordConfirmation, {
      message: t`Passwords must match`,
      path: ["passwordConfirmation"],
    });

export const getOnboardingPatientSchema = () =>
  z
    .object({
      firstName: z.string().min(1, { message: t`First name is required` }),
      lastName: z.string().min(1, { message: t`Last name is required` }),
      email: z
        .string()
        .min(1, { message: t`Email is required` })
        .email({ message: t`Invalid email` }),
      emailConfirmation: z.string(),
      language: z.string().min(1, { message: t`Language is required` }),
      state: z.string().min(1, { message: t`State is required` }),
      marketingConsent: z.boolean().optional(),
      hasMedicaidCoverage: z.boolean(),
    })
    .refine((data) => data.email === data.emailConfirmation, {
      message: t`Emails must match`,
      path: ["emailConfirmation"],
    });

export const getBirthInfoSchema = () =>
  z.object({
    gender: z.string().min(1, { message: t`Gender is required` }),
    dateOfBirth: z.string().min(1, { message: t`Date of birth is required` }),
  });

export const getPhoneNumberSchema = () =>
  z.string().refine((value) => !value || phoneRegex.test(value), {
    message: t`Invalid phone number`,
  });

export const getPatientSchema = () =>
  z
    .object({
      firstName: z.string().min(1, { message: t`First name is required` }),
      lastName: z.string().min(1, { message: t`Second name is required` }),
      gender: z.string().min(1, { message: t`Gender is required` }),
      dateOfBirth: z.string().min(1, { message: t`Date of birth is required` }),
      email: z
        .string()
        .min(1, { message: t`Email is required` })
        .email({ message: t`Invalid email` }),
      phoneNumber: getPhoneNumberSchema(),
      timezone: z.string().min(1, { message: t`Timezone is required` }),
      pharmacyName: z.string().optional(),
      pharmacyAddress: z.string().optional(),
      insuranceProvider: z.string().optional(),
      insuranceMemberId: z.string().optional(),
    })
    .merge(getLocationSchema())
    .merge(policySchema);

export const getPaymentSchema = () =>
  z
    .object({
      creditCard: z.literal<boolean>(true, {
        errorMap: () => ({ message: t`Credit card is required` }),
      }),
      phoneNumber: z
        .string()
        .min(1, { message: t`Phone number is required` })
        .refine((value) => !value || phoneRegex.test(value), {
          message: t`Invalid phone number format`,
        }),
      couponCode: z.string().optional(),
    })
    .merge(getLocationSchema());

const fileSchema = z
  .instanceof(File)
  .optional()
  .refine(
    (file) => {
      return !file || file.size <= MAX_UPLOAD_SIZE;
    },
    t`File size must be less than 2MB`,
  )
  .refine(
    (file) => {
      return !file?.type || ACCEPTED_IMAGE_TYPES.includes(file.type);
    },
    t`File must be a PNG, JPEG, or PDF`,
  );

export const getTempUploadedSchema = () =>
  z.array(
    z.object({
      id: z.string().min(1, {
        message: t`File required, try again or remove it`,
      }),
      name: z.string().min(1, { message: t`File name is required` }),
      file: z.instanceof(File),
    }),
  );

export const getUploadFilesSchema = () =>
  z.object({
    type: z.enum(getEnumValues(DOCUMENT_FOLDER)),
    files: getTempUploadedSchema(),
  });

export const getOnboardingImageUploadsSchema = () =>
  z.object({
    insuranceCardFront: fileSchema.nullable(),
    insuranceCardBack: fileSchema.nullable(),
    uploadedFiles: z.object({
      labs: getTempUploadedSchema(),
      other: getTempUploadedSchema(),
    }),
  });

export const getWeightEntrySchema = () =>
  z.object({
    id: z.string().min(1, { message: t`Id is required` }),
    date: z
      .string()
      .min(1, { message: t`Date is required` })
      .date(),
    weight: z
      .number({ invalid_type_error: t`Weight must be a number` })
      .positive({ message: t`A positive weight is required` })
      .gte(60, { message: t`The minimum weight is 60 lbs` })
      .lte(800, { message: t`The maximum weight is 800 lbs` }),
    note: z
      .string()
      .max(MAX_NOTE_CHARACTERS, { message: "Note is too long" })
      .optional(),
  });

export const journeySchema = z.nativeEnum(JOURNEY, {
  required_error: t`Please select your journey`,
  invalid_type_error: t`Invalid journey type`,
});

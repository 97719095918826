import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { JOURNEY } from "@/shared.constants";

export const RequireFormData = () => {
  const { pathname } = useLocation();
  const {
    mainInfoFormValues,
    chooseJourneyFormValues,
    scheduleVideoConsultationFormValues,
  } = useEmbeddablesForms();

  if (
    pathname === EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY &&
    !mainInfoFormValues
  ) {
    return <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  if (
    pathname === EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE ||
    pathname === EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_REFILL_REQUEST ||
    pathname === EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS
  ) {
    if (!mainInfoFormValues) {
      return <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO} />;
    }

    if (!chooseJourneyFormValues) {
      return (
        <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY} />
      );
    }
  }

  if (pathname === EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS) {
    if (
      chooseJourneyFormValues &&
      chooseJourneyFormValues.journey === JOURNEY.VIDEO_CONSULTATION &&
      !scheduleVideoConsultationFormValues
    ) {
      <Navigate
        replace
        to={EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE}
      />;
    }
  }

  return <Outlet />;
};

import { t } from "ttag";

import { env } from "@/env";
import { SUBSCRIPTION_STATUS } from "@/shared.constants";
import type { SubscriptionPlan, SubscriptionStatus } from "@/shared.types";
import { Alert, ConfettiMinimalisticIcon } from "@/ui";

interface SubscriptionAlertsProps {
  status: SubscriptionStatus;
  currentPeriodPlan: SubscriptionPlan | null;
  nextPeriodPlan: SubscriptionPlan | null;
}
export const SubscriptionAlerts = ({
  status,
  currentPeriodPlan,
  nextPeriodPlan,
}: SubscriptionAlertsProps) => {
  if (status === SUBSCRIPTION_STATUS.CANCELLED) {
    return (
      <Alert type="warning" className="border-current">
        {t`You've cancelled your subscription, so your account is on hold. You won't receive any medication or be charged until you reactivate it`}
      </Alert>
    );
  }

  if (status === SUBSCRIPTION_STATUS.PAYMENT_FAILED) {
    return (
      <Alert type="warning" className="border-current">
        {t`Your subscription has been cancelled, please update your payment method.`}
      </Alert>
    );
  }

  if (nextPeriodPlan) {
    return (
      <Alert className="border-salmon-05 bg-salmon-10 text-salmon-01">
        {t`You've already requested the subscription change to ${nextPeriodPlan.name} for your next billing period.`}
      </Alert>
    );
  }

  if (
    currentPeriodPlan?.subscriptionPriceId === env.VITE_STRIPE_BASE_PRICE_ID
  ) {
    return (
      <Alert
        type="warning"
        customIcon={<ConfettiMinimalisticIcon className="text-yellow-10" />}
        className="border-yellow-05"
      >
        <h6 className="font-bold text-yellow-10">
          {t`New subscriptions available!`}
        </h6>

        <p>
          {t`You can now choose from a variety of subscriptions with different
      offerings to get the Fridays experience that suits you best.`}
        </p>
      </Alert>
    );
  }

  return null;
};

import { Link } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { ROUTES } from "@/router";
import { Button, ConfettiFixedIconMono } from "@/ui";

export const SuccessScreen = () => (
  <FullScreenAlert
    customIcon={<ConfettiFixedIconMono className="size-20" color="salmon" />}
    title={t`You're officially part of the Fridays Crew!`}
    description={t`Congratulations! You are now part of Fridays. We're excited to join you on this wellness journey. Let's make every day feel like a Friday! 🎉`}
    customAction={
      <Button asChild size="lg" className="w-full sm:w-fit">
        <Link to={ROUTES.HOME}>{t`Start your journey`}</Link>
      </Button>
    }
  />
);
